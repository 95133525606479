
export default function abandonedCallsApiModule (instance) {
  return {

    /**
     * Get list of unresolved abandoned calls.
     * @param data
     * @returns {Promise<*>}
     */
    getUnresolvedAbandonCalls: async (data) => {
      const resource = '/abandoned-calls/unresolved'
      return await instance.get(resource, {
        params: data
      })
    },

    /**
     * Find list of unresolved abandoned calls.
     * @param data
     * @returns {Promise<*>}
     */
    findUnresolvedAbandonCalls: async (data) => {
      const resource = `/abandoned-calls/${data.call_from}`

      delete data.call_from

      return await instance.get(resource, {
        params: data
      })
    },

    /**
     * Save unresolved abandoned calls
     * @param data
     * @returns {Promise<*>}
     */
    saveAbandonCall: async (data) => {
      const resource = '/abandoned-calls'
      return await instance.post(resource, data)
    },

    /**
     * Update unresolved abandoned call status
     * @param data
     * @returns {Promise<*>}
     */
    updateAbandonCallStatus: async (data) => {
      const resource = `/abandoned-calls/${data.callId}`
      return await instance.patch(resource, {
        resolution_action: data.status
      })
    }

  }
}
