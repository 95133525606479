export default function abandonedCallsLocalCacherModule (indexDB) {
  return {
    get: async function (payload) {
      const data = indexDB.abandonedCalls.get(payload)

      return data
    },
    limit: function (limit) {
      return indexDB.abandonedCalls.limit(limit)
    },
    last: function () {
      return indexDB.abandonedCalls.last()
    },
    count: function (filter) {
      return indexDB.abandonedCalls.count(filter)
    },
    updateTable: (data) => {
      indexDB.abandonedCalls.insertMany(data)
    },
    delete: (id) => {
      indexDB.abandonedCalls.delete(id)
    },
    clear () {
      indexDB.abandonedCalls.clear()
    }
  }
}
