<template>
  <div>
    <v-card tile flat color="secondary_card">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="requestPair">
              <v-icon color="error">
                mdi-connection
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('pair') }}</span>
        </v-tooltip>
        <v-tooltip bottom
          v-if="(serialDevicesGetter.length && !serialDevicesGetter[0].isOpen) || !serialDevicesGetter.length">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="open" :disabled="!serialDevicesGetter.length || !selected.length">
              <v-icon color="success">
                mdi-check-bold
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('open') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="serialDevicesGetter.length && serialDevicesGetter[0].isOpen">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="close" :disabled="!serialDevicesGetter.length || !selected.length">
              <v-icon color="error">
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('close') }}</span>
        </v-tooltip>
        <v-btn @click="toggle">{{$t('toggle') }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table class="transparent-background" :headers="headers" :items="serialDevicesGetter"
          :items-per-page="10" show-select single-select v-model="selected">
          <template v-slot:[`item.usbVendorId`]="{ item }">
            <template>
              {{ item.device.getInfo().usbVendorId }}
            </template>
          </template>
          <template v-slot:[`item.usbProductId`]="{ item }">
            <template>
              {{ item.device.getInfo().usbProductId }}
            </template>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <template>
              {{ item.isOpen ? $t('opened') : $t('closed') }}
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ControllerSettingTab',
  data: () => ({
    devices: [],
    selected: [],
    isOn: false
  }),
  computed: {
    headers () {
      return [
        { text: this.$t('usb_vendor_id'), value: 'usbVendorId' },
        { text: this.$t('usb_product_id'), value: 'usbProductId' },
        { text: this.$t('state'), value: 'state' }
      ]
    },
    ...mapGetters('global', [
      'serialDevicesGetter'
    ]),
    ...mapGetters('settings', ['getUserSetting'])
  },
  watch: {
    devices () {
      console.log(this.devices)
    }
  },
  methods: {
    requestPair () {
      this.$serial.requestPair()
    },
    open () {
      this.$serial.openMany(this.selected)
    },
    close () {
      this.$serial.closeMany(this.selected)
    },
    toggle () {
      if (this.isOn) this.$serial.toggleOffAll()
      else this.$serial.toggleOnAll()

      this.isOn = !this.isOn
    }
  }
}
</script>
