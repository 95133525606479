import i18n from '@/plugins/i18n'

import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export const loadDashboardIcons = async (context) => {
  const icons = [
    {
      label: i18n.t('map'),
      value: 'mdi-map',
      icon: 'mdi-map'
    },
    {
      label: i18n.t('phone'),
      value: 'mdi-cellphone-basic',
      icon: 'mdi-cellphone-basic'
    },
    {
      label: i18n.t('account'),
      value: 'mdi-account-group',
      icon: 'mdi-account-group'
    },
    {
      label: i18n.t('ambulance'),
      value: 'mdi-ambulance',
      icon: 'mdi-ambulance'
    }
  ]

  context.commit('dashboardsIconsMutator', icons)
}

/**
 * Load dashboard from api
 * @param context
 * @returns {Promise<void>}
 */
export const loadDashboardsAction = async (context) => {
  await apiService.userDashboard.getList().then((response) => {
    context.commit('dashboardsMutator', response.data)

    const defaultDashboardId = localStorageService.getDefaultDashboard()

    const dashboards = response.data

    if (defaultDashboardId) {
      const defaultDashboard = dashboards.find(obj => obj.dashboard_id === Number.parseInt(defaultDashboardId))
      context.dispatch('setCurrentDashboardAction', defaultDashboard)
    } else {
      localStorageService.setDefaultDashboard(dashboards[0].dashboard_id)
      context.dispatch('setCurrentDashboardAction', dashboards[0])
    }
  })
}

/**
 * Load list of widgets with default values
 * @param context
 * @returns {Promise<void>}
 */
export const loadWidgetsAction = async (context) => {
  const list = [
    {
      id: 1,
      displayName: i18n.t('LogoAndClockComponent'),
      name: 'LogoAndClockComponent',
      configuration: {
        tabName: i18n.t('LogoAndClockComponent')
      }
    },
    {
      id: 2,
      displayName: i18n.t('CallControlComponent'),
      name: 'CallControlComponent',
      configuration: {
        tabName: i18n.t('CallControlComponent')
      }
    },
    {
      id: 4,
      displayName: i18n.t('admin_calls'),
      name: 'AdminCallComponent',
      configuration: {
        tabName: i18n.t('admin_calls')
      }
    },
    {
      id: 7,
      displayName: i18n.t('emergency_calls'),
      name: 'EmergencyCallLineComponent',
      configuration: {
        tabName: i18n.t('emergency_calls')
      }
    },
    {
      id: 8,
      displayName: i18n.t('map'),
      name: 'MapComponent',
      configuration: {
        tabName: i18n.t('map')
      }
    },
    {
      id: 9,
      displayName: i18n.t('call_information'),
      name: 'CallInfoComponent',
      configuration: {
        tabName: i18n.t('call_information')
      }
    },
    {
      id: 10,
      displayName: i18n.t('held'),
      name: 'HeldInformationComponent',
      configuration: {
        tabName: i18n.t('held')
      }
    },
    {
      id: 11,
      displayName: i18n.t('adr'),
      name: 'AdrInformationComponent',
      configuration: {
        tabName: i18n.t('adr')
      }
    },
    {
      id: 12,
      displayName: i18n.t('call_history'),
      name: 'CallHistoryComponent',
      configuration: {
        tabName: i18n.t('call_history')
      }
    },
    {
      id: 13,
      displayName: i18n.t('abandoned_call'),
      name: 'AbandonedCallsComponent',
      configuration: {
        tabName: i18n.t('abandoned_call')
      }
    },
    {
      id: 14,
      displayName: i18n.t('fcc_master_registry'),
      name: 'FccMasterRegistryComponent',
      configuration: {
        tabName: i18n.t('fcc_master_registry')
      }
    },
    {
      id: 15,
      displayName: i18n.t('speed_dial'),
      name: 'SpeedDialComponent',
      configuration: {
        tabName: i18n.t('speed_dial')
      }
    },
    {
      id: 16,
      displayName: i18n.t('contact_book'),
      name: 'ContactBookComponent',
      configuration: {
        tabName: i18n.t('contact_book')
      }
    },
    {
      id: 17,
      displayName: i18n.t('agents'),
      name: 'AgentsComponent',
      configuration: {
        tabName: i18n.t('agents')
      }
    },
    {
      id: 18,
      displayName: i18n.t('lost'),
      name: 'LostComponent',
      configuration: {
        tabName: i18n.t('lost')
      }
    }

  ]

  context.commit('widgetsMutator', list)
}

/**
 * Set current dashboard
 * @param context
 * @param dashboard
 * @returns {Promise<void>}
 */
export const setCurrentDashboardAction = async (context, dashboard) => {
  context.commit('currentDashboardMutator', dashboard)
}

/**
 * Show/Hide dashboard
 * @param context
 * @param show
 * @returns {Promise<void>}
 */
export const setShowDashboardAction = async (context, show) => {
  context.commit('showDashboardMutator', show)
}

/**
 * Update user dashboard
 * @param context
 * @param config
 * @returns {Promise<void>}
 */
export const updateDashboardAction = async (context, config) => {
  await apiService.userDashboard.update(config.id, config).then(async (response) => {
    await context.dispatch('loadDashboardsAction')
  })
}
