import store from '@/store'
import preRecordedAudioMixin from '@/mixins/sip/pre-recorded-audio.mixin'
import conferenceMixin from '@/mixins/sip/conference.mixin'
import localStorageService from '@/util/local-storage-service'
import { mapGetters } from 'vuex'

export default {
  mixins: [preRecordedAudioMixin, conferenceMixin],
  computed: {
    ...mapGetters('settings', ['getUserSetting'])
  },
  methods: {
    incomingEvents () {
      this.$event.listen('incoming-connecting', e => {
      })
      this.$event.listen('incoming-accepted', e => {
        store.commit('callInformation/dateTimeMutator', e.session.start_time)
      })
      this.$event.listen('incoming-sending', e => {
      })
      this.$event.listen('incoming-progress', e => {
        store.commit('audioVolume/ringingMutator', this.$uuidKey())
        this.$sip.isAdminCall(e.session._request) ? this.$event.dispatch('newAdminCall', { event: e, type: 'admin' }) : this.$event.dispatch('newEmergencyCall', { event: e, type: 'emergency' })
      })
      this.$event.listen('incoming-confirmed', async e => {
        await store.commit('global/callMutator', { type: 'canMute', data: true })
        await store.commit('global/callMutator', { type: 'canLocalHold', data: true })
        await store.commit('global/callMutator', { type: 'hasActiveCall', data: true })
        await store.commit('global/callMutator', { type: 'isAdmin', data: this.$sip.isAdminCall(e.session._request) })
        await store.commit('global/callMutator', { type: 'isEmergency', data: !this.$sip.isAdminCall(e.session._request) })
        await store.commit('global/callMutator', { type: 'isConference', data: this.$sip.isConferenceCall(e.session._request) })
        await store.commit('global/callMutator', { type: 'canGlobalHold', data: this.$sip.isConferenceCall(e.session._request) })
        await store.commit('audioVolume/ringingMutator', false)
        // store.commit('recorder/recordMutator', true)
        this.$event.dispatch('incoming-call-answer')
        this.$event.dispatch('openChat', e)
        this.$event.dispatch('serialHooksOn')
        this.$sip.answeredCallSession = e.session
        await this.setConferenceData(e.session)
        await localStorageService.setMicrophoneSource(this.$sip.answeredCallSession)
        // await this.playPreRecordedAudio()
      })
      this.$event.listen('incoming-ended', async e => {
        if (!e.session.isOnHold().local) {
          await store.commit('audioVolume/ringingMutator', this.$uuidKey())
          await store.commit('global/callMutator', { type: 'hasActiveCall', data: false })
          await store.commit('global/callMutator', { type: 'isAdmin', data: false })
          await store.commit('global/callMutator', { type: 'isEmergency', data: false })
          await store.commit('global/callMutator', { type: 'canMute', data: false })
          await store.commit('global/callMutator', { type: 'canLocalHold', data: false })
          await store.commit('global/callMutator', { type: 'localHold', data: false })
          await store.commit('global/callMutator', { type: 'localMuted', data: false })
          await store.commit('global/callMutator', { type: 'isConference', data: false })
          await store.commit('global/callMutator', { type: 'canGlobalHold', data: false })
          // store.commit('recorder/recordMutator', false)
          // store.commit('recorder/toggleMutator', false)
          this.$event.dispatch('serialHooksOff')
          this.clearConferenceData()
          this.$sip.isAdminCall(e.session._request) ? this.$event.dispatch('newAdminCall', { event: e, type: 'admin' }) : this.$event.dispatch('newEmergencyCall', { event: e, type: 'emergency' })
          // Save Call history
          await store.dispatch('callHistory/saveCallHistoryAction', e)
        } else {
          this.$sip.isAdminCall(e.session._request) ? this.$event.dispatch('newAdminCall', { event: e, type: 'admin' }) : this.$event.dispatch('newEmergencyCall', { event: e, type: 'emergency' })
          // Save Call history
          await store.dispatch('callHistory/saveCallHistoryAction', e)
        }
      })
      this.$event.listen('incoming-failed', async e => {
        this.$event.dispatch('serialHooksOff')
        store.commit('audioVolume/ringingMutator', this.$uuidKey())
        this.$sip.isAdminCall(e.session._request) ? this.$event.dispatch('newAdminCall', { event: e, type: 'admin' }) : this.$event.dispatch('newEmergencyCall', { event: e, type: 'emergency' })
        // Save Call history
        await store.dispatch('callHistory/saveCallHistoryAction', e)
        // Save abandon call
        await store.dispatch('abandonedCalls/saveAbandonedCallAction', e)
        this.clearConferenceData()
      })
      this.$event.listen('incoming-newDTMF', e => {
      })
      this.$event.listen('incoming-newInfo', e => {
      })
      this.$event.listen('incoming-hold', e => {
        store.commit('global/callMutator', { type: 'localHold', data: true })
      })
      this.$event.listen('incoming-unhold', e => {
        store.commit('global/callMutator', { type: 'localHold', data: false })
      })
      this.$event.listen('incoming-muted', e => {
        store.commit('global/callMutator', { type: 'localMuted', data: true })
      })
      this.$event.listen('incoming-unmuted', e => {
        store.commit('global/callMutator', { type: 'localMuted', data: false })
      })
      this.$event.listen('incoming-reinvite', e => {
      })
      this.$event.listen('incoming-update', e => {
      })
      this.$event.listen('incoming-refer', e => {
      })
      this.$event.listen('incoming-replaces', e => {
      })
      this.$event.listen('incoming-peerconnection', e => {
        this.$event.dispatch('setRemoteAudio', e)
      })
      this.$event.listen('incoming-sdp', e => {
      })
      this.$event.listen('incoming-icecandidate', e => {
      })
      this.$event.listen('incoming-getusermediafailed', e => {
      })
      this.$event.listen('on-hold-call', async e => {
        if (this.$sip.answeredCallSession?.direction === 'incoming') {
          this.$event.dispatch('serialHooksOff')
          // store.commit('recorder/recordMutator', false)
          // store.commit('recorder/toggleMutator', false)
          if (this.$sip.answeredCallSession.isMuted().local) this.$sip.answeredCallSession.unmute()
          this.$sip.answeredCallSession = null
          this.clearConferenceData()
          store.commit('audioVolume/ringingMutator', this.$uuidKey())
          store.commit('global/callMutator', { type: 'hasActiveCall', data: false })
          store.commit('global/callMutator', { type: 'isAdmin', data: false })
          store.commit('global/callMutator', { type: 'isEmergency', data: false })
          store.commit('global/callMutator', { type: 'canMute', data: false })
          store.commit('global/callMutator', { type: 'canLocalHold', data: false })
          store.commit('global/callMutator', { type: 'localHold', data: false })
          store.commit('global/callMutator', { type: 'localMuted', data: false })
          store.commit('global/callMutator', { type: 'isConference', data: false })
          store.commit('global/callMutator', { type: 'canGlobalHold', data: false })
        }
      })
    },
    outgoingEvents () {
      /**
       * Outgoing Connecting
       * */
      this.$event.listen('outgoing-connecting', e => {
        store.commit('global/callMutator', { type: 'calling', data: true })
      })
      /**
       * Outgoing Accepted
       * */
      this.$event.listen('outgoing-accepted', e => {
      })
      /**
       * Outgoing Sending
       * */
      this.$event.listen('outgoing-sending', e => {
        this.$event.dispatch('setRemoteAudio', e)
      })
      /**
      * Outgoing Progress
      * */
      this.$event.listen('outgoing-progress', e => {
        this.$sip.answeredCallSession = e.session
        store.commit('global/callMutator', { type: 'calling', data: false })
        store.commit('global/callMutator', { type: 'hasActiveCall', data: true })
      })
      /**
       * Outgoing Confirmed
       * */
      this.$event.listen('outgoing-confirmed', e => {
        this.$event.dispatch('serialHooksOn')
        this.$sip.answeredCallSession = e.session
        // store.commit('recorder/recordMutator', true)
        // store.commit('global/callMutator', { type: 'calling', data: false })
        store.commit('global/callMutator', { type: 'hasActiveCall', data: true })
        store.commit('global/callMutator', { type: 'canMute', data: true })
        store.commit('global/callMutator', { type: 'canLocalHold', data: true })
        this.$mitoring.monitorCall(e.session)
      })
      /**
       * Outgoing Ended
       * */
      this.$event.listen('outgoing-ended', async e => {
        this.$event.dispatch('serialHooksOff')
        this.$sip.answeredCallSession = null
        // store.commit('recorder/recordMutator', false)
        // store.commit('recorder/toggleMutator', false)
        store.commit('global/callMutator', { type: 'hasActiveCall', data: false })
        store.commit('global/callMutator', { type: 'calling', data: false })
        store.commit('global/callMutator', { type: 'canMute', data: false })
        store.commit('global/callMutator', { type: 'canLocalHold', data: false })
        store.commit('global/callMutator', { type: 'localHold', data: false })
        store.commit('global/callMutator', { type: 'localMuted', data: false })
        // Save Call history
        await store.dispatch('callHistory/saveCallHistoryAction', e)
      })
      /**
       * Outgoing Failed
       * */
      this.$event.listen('outgoing-failed', async e => {
        this.$event.dispatch('serialHooksOff')
        this.$sip.answeredCallSession = null
        store.commit('global/callMutator', { type: 'calling', data: false })
        store.commit('global/callMutator', { type: 'hasActiveCall', data: false })
        // Save Call history
        await store.dispatch('callHistory/saveCallHistoryAction', e)
      })
      /**
       * Outgoing newDTMF
       * */
      this.$event.listen('outgoing-newDTMF', e => {
      })
      /**
       * Outgoing NewInfo
       * */
      this.$event.listen('outgoing-newInfo', e => {
      })
      /**
       * Outgoing Hold
       * */
      this.$event.listen('outgoing-hold', e => {
        store.commit('global/callMutator', { type: 'localHold', data: true })
      })
      /**
       * Outgoing Unhold
       * */
      this.$event.listen('outgoing-unhold', e => {
        store.commit('global/callMutator', { type: 'localHold', data: false })
      })
      /**
       * Outgoing Muted
       * */
      this.$event.listen('outgoing-muted', e => {
        store.commit('global/callMutator', { type: 'localMuted', data: true })
      })
      /**
       * Outgoing Unmuted
       * */
      this.$event.listen('outgoing-unmuted', e => {
        store.commit('global/callMutator', { type: 'localMuted', data: false })
      })
      /**
       * Outgoing Reinvite
       * */
      this.$event.listen('outgoing-reinvite', e => {
      })
      /**
       * Outgoing Update
       * */
      this.$event.listen('outgoing-update', e => {
      })
      /**
       * Outgoing Refer
       * */
      this.$event.listen('outgoing-refer', e => {
      })
      /**
       * Outgoing Replaces
       * */
      this.$event.listen('outgoing-replaces', e => {
      })
      /**
       * Outgoing SDP
       * */
      this.$event.listen('outgoing-sdp', e => {
      })
      /**
       * Outgoing Icecandidate
       * */
      this.$event.listen('outgoing-icecandidate', e => {
      })
      /**
       * Outgoing Getusermediafailed
       * */
      this.$event.listen('outgoing-getusermediafailed', e => {
      })
    },
    connectionEvents () {
      this.$event.listen('connecting', e => {
        store.commit('global/sipMutator', { type: 'connecting', data: true })
        store.commit('global/sipMutator', { type: 'connected', data: false })
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: false })
      })
      this.$event.listen('connected', e => {
        store.commit('global/sipMutator', { type: 'connecting', data: false })
        store.commit('global/sipMutator', { type: 'connected', data: true })
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: false })
      })
      this.$event.listen('disconnected', e => {
        store.commit('global/sipMutator', { type: 'connecting', data: false })
        store.commit('global/sipMutator', { type: 'connected', data: false })
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: false })
      })
      this.$event.listen('connectionFailed', e => {
        store.commit('global/sipMutator', { type: 'connecting', data: false })
        store.commit('global/sipMutator', { type: 'connected', data: false })
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: false })
      })
      this.$event.listen('registered', e => {
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: true })
      })
      this.$event.listen('unregistered', e => {
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: false })
      })
      this.$event.listen('registrationFailed', e => {
        store.commit('global/sipMutator', { type: 'registering', data: false })
        store.commit('global/sipMutator', { type: 'registered', data: false })
      })
    }
  }
}
