import apiService from '@/modules/api/csp'
import CallHistoryTransformer from '@/modules/api/csp/transformers/call-history.transformer'
import LocalCacher from '@/modules/cache/localCacher'
import indexedDBSyncService from '@/util/indexedDB-sync-service'

export const getUnresolvedAbandonedCallsAction = async ({ commit, dispatch }, payload = {}) => {
  indexedDBSyncService.sync()
}

export const setUnresolvedAbandonedCallsCountAction = async ({ commit, dispatch }, payload = 0) => {
  commit('setAbandonedCallsMutator', payload)
}

export const saveAbandonedCallAction = async ({ commit, dispatch }, payload = []) => {
  try {
    await new CallHistoryTransformer(payload).collectData().then(async data => {
      await apiService.abandonedCalls.saveAbandonCall(data).then(async () => {
        indexedDBSyncService.sync()
      })
    })
  } catch (e) {
    console.log('An error occurred during save abandoned call')
    return true
  }
}

export const updateAbandonCallStatusAction = async ({ commit, dispatch }, payload = {}) => {
  LocalCacher.indexDB.abandonedCalls.delete(payload.call_from)
  await apiService.abandonedCalls.updateAbandonCallStatus(payload).then()
}
