import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import adrXmlParser from '@/modules/api/csp/parsers/adr/adr-xml.parser'

export default class CallHistoryTransformer {
  event;
  reason;
  saveCall = false;
  dataObj = {}
  locationObj = {}
  adrObj = {}
  callBackNumber = {}
  constructor (event) {
    this.event = event
    this.getReason()
  }

  async collectData () {
    if (this.saveCall) {
      this.setRequestData()
      this.getLocations()
      await this.getAdr()
      return { ...this.dataObj, ...this.locationObj, ...this.adrObj, ...this.callBackNumber }
    }
  }

  getReason () {
    this.reason = this.event.eventData.message?.headers?.Reason?.[0].raw.match(/text="(.*?)"/)
    if (!this.reason || (this.reason[1] && this.reason[1] !== 'Call completed elsewhere')) {
      this.saveCall = true
    }
  }

  setRequestData () {
    this.dataObj = {
      call_id: this.event.session?._request?.call_id,
      call_direction: this.event.session?.direction,
      invite_message: this.event.session?._request?.toString(),
      hangup_cause: this.reason?.[1] || null,
      resolution_action: 'unresolved'
    }
  }

  getLocations () {
    if (this.event.session?._request?.headers?.Geolocation) {
      this.locationObj = new EmergencyCallDataParser().parseGeoLocation(this.event.session._request.headers.Geolocation)
    }
  }

  async getAdr () {
    if (this.event.session?._request?.headers?.['Call-Info']) {
      this.adrObj = new EmergencyCallDataParser().parseAdr(this.event.session._request.headers['Call-Info'])
      await this.getCallBeckNumber(this.adrObj?.subscriber_info_url)
    } else {
      await this.getCallBeckNumber(null)
    }
  }

  async getCallBeckNumber (subscriberInfoUrl) {
    if (subscriberInfoUrl) {
      await adrXmlParser().parse(subscriberInfoUrl, 'SubscriberInfo').then(data => {
        const callBackNumber = data?.SubscriberData?.vcard?.tel?.uri?.replace('tel:', '')
        if (callBackNumber) {
          this.callBackNumber.call_from = callBackNumber
        } else {
          this.callBackNumber.call_from = (this.event.session?.direction === 'incoming') ? this.event.session?._request?.from?._uri?._user : this.event.session?._request?.to?._uri?._user
        }
      })
    } else {
      this.callBackNumber.call_from = (this.event.session?.direction === 'incoming') ? this.event.session?._request?.from?._uri?._user : this.event.session?._request?.to?._uri?._user
    }
  }
}
