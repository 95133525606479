<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        @click="onLogoutClick"
        icon
        color="grey_light"
        text
        v-on="on"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('logout') }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'LogoutButton',
  data: () => ({
    loading: false
  }),

  methods: {

    ...mapActions('global', ['authLogoutAction']),

    onLogoutClick () {
      this.loading = true
      this.authLogoutAction().then(() => {
        this.loading = false
        location.reload()
      })
    }
  }
}
</script>

<style scoped>

</style>
