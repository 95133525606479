import LocalCacher from '@/modules/cache/localCacher'

export const setAbandonedCallsMutator = async (state, payload) => {
  if (payload.data) {
    LocalCacher.indexDB.abandonedCalls.updateTable(payload.data)

    if (payload.data.length) {
      state.abandonedCalls = payload.data.length
    }

    return
  }

  state.abandonedCalls = payload.value ? payload.value : payload
}
