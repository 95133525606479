<template>
  <v-simple-table fixed-header>
    <thead>
    <tr>
      <th class="chs-grey-modal-bg">{{ $t('hot_key')}}</th>
      <th class="chs-grey-modal-bg">{{ $t('definition')}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Alt + C</td>
      <td>{{ $t('connection')}}</td>
    </tr>
    <tr>
      <td>Alt + R</td>
      <td>{{ $t('ready')}}</td>
    </tr>
    <tr>
      <td>Ctrl + Space</td>
      <td>{{ $t('answer')}}</td>
    </tr>
    <tr>
      <td>Ctrl + Alt</td>
      <td>{{ $t('hang_up')}}</td>
    </tr>
    <tr>
      <td>Alt + H</td>
      <td>{{ $t('hold')}}</td>
    </tr>
    <tr>
      <td>Alt + M</td>
      <td>{{ $t('mute')}}</td>
    </tr>
    <tr>
      <td>Alt + P</td>
      <td>{{ $t('global_parking')}}</td>
    </tr>
    <tr>
      <td>Ctrl + Enter</td>
      <td>{{ $t('make_a_call_from_dial_pad')}}</td>
    </tr>
    <tr>
      <td>Backspace</td>
      <td>{{ $t('delete_last_number_from_dial_pad')}}</td>
    </tr>
    <tr>
      <td>Delete</td>
      <td>{{ $t('clear_dial_pad')}}</td>
    </tr>
    <tr>
      <td>Numpad subtract (-) </td>
      <td>{{ $t('open_and_close_dial_pad')}}</td>
    </tr>
    <tr>
      <td>Numpad add (+) </td>
      <td>+</td>
    </tr>
    <tr>
      <td>Numpad multiply (*) </td>
      <td>*</td>
    </tr>
    <tr>
      <td>Numpad divide (/) </td>
      <td>#</td>
    </tr>
    <tr>
      <td>Numpad 0</td>
      <td>0</td>
    </tr>
    <tr>
      <td>Numpad 1</td>
      <td>1</td>
    </tr>
    <tr>
      <td>Numpad 2</td>
      <td>2</td>
    </tr>
    <tr>
      <td>Numpad 3</td>
      <td>3</td>
    </tr>
    <tr>
      <td>Numpad 4</td>
      <td>4</td>
    </tr>
    <tr>
      <td>Numpad 5</td>
      <td>5</td>
    </tr>
    <tr>
      <td>Numpad 6</td>
      <td>6</td>
    </tr>
    <tr>
      <td>Numpad 7</td>
      <td>7</td>
    </tr>
    <tr>
      <td>Numpad 8</td>
      <td>8</td>
    </tr>
    <tr>
      <td>Numpad 9</td>
      <td>9</td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'HotkeysInformationComponent'
}
</script>

<style scoped>

</style>
