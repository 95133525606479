export const authUserGetter = (state) => {
  return state.authUserState
}
export const hasAnsweredCallGetter = (state) => {
  return state.hasAnsweredCall
}

export const sipGetter = (state) => {
  return state.sipState
}

export const callGetter = (state) => {
  return state.callState
}

export const serialDevicesGetter = (state) => {
  return state.serialDevices
}

export const getRingtoneByType = state => (type) => {
  console.log(state.realmRingtones)

  const realmRingtone = state.realmRingtones.find(item => item.type === type)

  if (realmRingtone) {
    return realmRingtone.file_url
  }

  const globalRingtone = state.globalRingtones.find(item => item.type === type)

  if (globalRingtone) {
    return globalRingtone.file_url
  }
}

export const changeFontSizeGetter = (state) => {
  return state.fontsize
}
