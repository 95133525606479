import Vue from 'vue'
import scrolldiv from 'vue-scroll-div'
import store from '@/store'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify.plugin'
import sip from '@/plugins/sip.plugin'
import event from '@/plugins/event.plugin'
import router from '@/router'
import i18n from '@/plugins/i18n'
import DtmfTones from '@/plugins/dtmf-tones.plugin'
import permissionsMixin from '@/mixins/permissions.mixin'
import rolesMixin from '@/mixins/roles.mixin'
import sipEventsMixin from '@/mixins/sip/sip-events.mixin'
import microphonePermissionMixin from '@/mixins/audio/microphone-permission.mixin'
import audioDevicesMixin from '@/mixins/audio/audio-devices.mixin'
import audioVolumeMixin from '@/mixins/audio/audio-volume.mixin'
import offlineMixin from '@/mixins/offline.mixin'
import filters from '@/filters'
import confirm from '@/plugins/confirm.plugin'
import serial from '@/plugins/serial.plugin'
import localCacher from '@/plugins/localCacher.plugin'
import uuid from '@estudioliver/vue-uuid-v4'
import timer from '@/plugins/timer.plugin'
import socket from '@/plugins/socket.plugin'
import CAD from '@/plugins/cad.plugin'
import serialDeviceEventsMixin from '@/mixins/serial-device-events.mixin'
import vgl from 'vue-golden-layout'
import Logger from '@/plugins/logger.plugin'

Vue.config.productionTip = false

/* Global mixins */
Vue.mixin(permissionsMixin)
Vue.mixin(rolesMixin)
Vue.mixin(sipEventsMixin)
Vue.mixin(microphonePermissionMixin)
Vue.mixin(audioDevicesMixin)
Vue.mixin(audioVolumeMixin)
Vue.mixin(offlineMixin)
Vue.mixin(serialDeviceEventsMixin)

/* Adding plugins */
Vue.use(sip)
Vue.use(event)
Vue.use(filters)
Vue.use(confirm)
Vue.use(scrolldiv)
Vue.use(serial)
Vue.use(DtmfTones)
Vue.use(localCacher)
Vue.use(uuid)
Vue.use(timer)
Vue.use(socket)
Vue.use(CAD)
Vue.use(vgl)
Vue.use(Logger)

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
