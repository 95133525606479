<template>
  <div>
    <v-card tile flat color="secondary_card">
      <v-row>
        <v-col>
          <v-text-field v-model="domain" :placeholder="$t('domain_or_ip')"></v-text-field>
        </v-col>
        <v-col class="col-1">
          <v-text-field v-model="port" :placeholder="$t('port')"></v-text-field>
        </v-col>
        <v-col class="d-flex align-center">
          <v-btn :color="connected ? 'success' : 'orange'" @click="connect">{{connected ? $t('connected') : $t('connect')}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="sendToText" :placeholder="$t('text_to_send')"></v-text-field>
        </v-col>
        <v-col class="d-flex align-center">
          <v-btn color="success" @click="sendText">{{$t('send')}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="sendTestData">{{ $t('send_test_data')}}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CADSettingTab',

  data: () => ({
    port: '',
    domain: '',
    connected: false,
    sendToText: ''
  }),
  methods: {
    connect () {
      this.$socket.emit('connect-cad-spill', {
        port: this.port,
        domain: this.domain
      })

      this.$socket.on('cad_spill_connected', () => {
        this.connected = true
      })
    },
    sendText () {
      this.$socket.emit('send-cad-text', {
        text: this.sendToText
      })
    },
    sendTestData () {
      const text = this.generateData()

      console.log('text', text)

      this.$socket.emit('send-cad-text', { text })
    },
    generateData () {
      const data = new Uint8Array(441)

      data[0] = 0x02

      // Add Response Type
      data[1] = 2

      // Add Possition number
      data[2] = 0
      data[3] = 3

      // Carriage Return
      data[4] = 0x0d

      // Telephone Number
      let index = this.fillData(data, 5, '(626) 208-7450')

      // Space
      index = this.fillData(data, index, '   ')

      // Time
      index = this.fillData(data, index, '11:51')

      // Space
      index = this.fillData(data, index, '    ')

      // Date
      index = this.fillData(data, index, '07/14')

      // Carriage Return
      data[index++] = 0x0d

      // House#
      index = this.fillData(data, index, '   30251')

      // Space
      data[index++] = ' '

      // House# Suffix
      index = this.fillData(data, index, '    ')

      // Space
      data[index++] = ' '

      // Prefix Direction
      index = this.fillData(data, index, 'N ')

      // Space
      data[index++] = ' '

      // Street Name
      index = this.fillData(data, index, 'MURRIETA RD                                    ')

      // City
      index = this.fillData(data, index, 'MENIFEE           ')

      // State abbr.
      index = this.fillData(data, index, 'CW')

      // Space
      data[index++] = ' '

      // State abbr.
      index = this.fillData(data, index, '808')

      // Space
      data[index++] = ' '

      // State abbr.
      index = this.fillData(data, index, 'WPH2')

      // Carriage Return
      data[index++] = 0x0d

      // Customer Name
      index = this.fillData(data, index, 'T-MOBILE USA                    ')

      // Carriage Return
      data[index++] = 0x0d

      // Spaces
      index = this.fillData(data, index, '                ')

      // Pilot Number
      index = this.fillData(data, index, '(951) 511-1126')

      // Carriage Return
      data[index++] = 0x0d

      // Location Info
      index = this.fillData(data, index, 'IE04345A-3-SE Sector')

      // Carriage Return
      data[index++] = 0x0d

      // Company ID
      index = this.fillData(data, index, 'TMOB ')

      // Spaces
      index = this.fillData(data, index, '  ')

      // Telco Comment
      index = this.fillData(data, index, '                       ')

      // Carriage Return
      data[index++] = 0x0d

      // New-Line Char.
      data[index++] = 0x0a

      // Police
      index = this.fillData(data, index, 'MENIFEE PD-MURRIETA PD/CHP SD  ')

      // Carriage Return
      data[index++] = 0x0d

      // Fire
      index = this.fillData(data, index, 'QUERY CALLER                   ')

      // Carriage Return
      data[index++] = 0x0d

      // EMS
      index = this.fillData(data, index, 'FOR LOCATION                   ')

      // Carriage Return
      data[index++] = 0x0d

      // Label
      index = this.fillData(data, index, 'LAT')

      // Space
      data[index++] = ' '

      // Latitude
      index = this.fillData(data, index, '33.68448400')

      // Space
      data[index++] = ' '

      // Label
      index = this.fillData(data, index, 'LON')

      // Space
      data[index++] = ' '

      // Longitude
      index = this.fillData(data, index, '-117.206536')

      // Carriage Return
      data[index++] = 0x0d

      // Label
      index = this.fillData(data, index, 'METERS')

      // Space
      data[index++] = ' '

      // Uncertainty
      index = this.fillData(data, index, '65     ')

      // Spaces
      index = this.fillData(data, index, '     ')

      // Label
      index = this.fillData(data, index, 'PERCENT')

      // Space
      data[index++] = ' '

      // Confidence Pct.
      index = this.fillData(data, index, '090')

      // Space
      data[index++] = ' '

      // Carriage Return
      data[index++] = 0x0d

      // Spaces (future)
      index = this.fillData(data, index, '                               ')

      // Carriage Return
      data[index++] = 0x0d

      data[440] = 0x03

      console.log('index', index)

      return data
    },
    fillData (data, index, text) {
      let i = 0

      for (i; i < text.length; i++) {
        data[index + i] = text[i].charCodeAt(0)
      }

      return index + i
    }
  }
}
</script>
