import cacheControl from '@/helpers/cache.control'
export default function egressApiModule (instance) {
  return {

    /**
     * Get authorization account
     * @param data
     * @returns {Promise<*>}
     */
    getAuthorization: async (data) => {
      const resource = '/egress-service/authorization'

      return await instance.get(resource)
    },

    /**
     * Get users
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getUsers: async (accountId, params) => {
      const timestamp = cacheControl.getTimestamp('egress')

      const resource = `/egress-service/users/${accountId}?${timestamp}`

      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get users list
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getUserList: async (accountId, params) => {
      const timestamp = cacheControl.getTimestamp('egress')

      const resource = `egress-service/accounts/${accountId}/users?${timestamp}`

      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get Devices list
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getDevicesList: async (accountId, params) => {
      const timestamp = cacheControl.getTimestamp('egress')

      const resource = `egress-service/accounts/${accountId}/devices?${timestamp}`

      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getAccounts: async (accountId, params) => {
      const timestamp = cacheControl.getTimestamp('egress')

      const resource = `/egress-service/accounts/${accountId}?${timestamp}`
      return await instance.get(resource, {
        params: params
      })
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getHoldConferences: async (accountId) => {
      const timestamp = cacheControl.getTimestamp('egress')

      const resource = `/egress-service/accounts/${accountId}/conferences/on-hold?${timestamp}`
      return await instance.get(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param conferenceId
     * @returns {Promise<*>}
     */
    getConference: async (accountId, conferenceId) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}`
      return await instance.get(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    getConferenceParticipants: async (accountId, conferenceId) => {
      const timestamp = cacheControl.getTimestamp('egress')

      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/participants?${timestamp}`
      return await instance.get(resource)
    },

    /**
     * Get accounts
     * @returns {Promise<*>}
     * @param data
     */
    kickConferenceParticipant: async (data) => {
      const resource = `/egress-service/accounts/${data.accountId}/conferences/${data.conferenceId}/participants/${data.participantId}/kick`
      return await instance.put(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    dialConference: async (accountId, conferenceId) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/dial`
      return await instance.put(resource)
    },

    /**
     * Get accounts
     * @param accountId
     * @param params
     * @returns {Promise<*>}
     */
    putConferenceOnHold: async (accountId, conferenceId, params) => {
      const resource = `/egress-service/accounts/${accountId}/conferences/${conferenceId}/on-hold`
      return await instance.put(resource, params)
    },

    getChannels: async (accountId) => {
      const resource = `/egress-service/accounts/${accountId}/channels`
      return await instance.get(resource)
    },

    getRecordings: async (accountId, query = {}) => {
      const resource = `/egress-service/accounts/${accountId}/recordings`
      return await instance.get(resource, {
        params: query
      })
    },

    getRecordedMedia: async (accountId, recordingId) => {
      const resource = `/egress-service/accounts/${accountId}/recordings/${recordingId}`
      return await instance.get(resource, {
        headers: { Accept: 'audio/mpeg' },
        responseType: 'blob'
      })
    },

    startTddDetection: async (accountId, uuid) => {
      const resource = `/egress-service/accounts/${accountId}/channels/tdd/${uuid}`

      return await instance.get(resource)
    },

    sendTddText: async (accountId, uuid, text) => {
      const resource = `/egress-service/accounts/${accountId}/channels/${uuid}/${text}`

      return await instance.get(resource)
    }
  }
}
