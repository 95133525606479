<template>
  <v-app class="app" :style="{background: $vuetify.theme.themes[theme].background}">
    <page-loader-component/>
    <v-app-bar v-if="authUserGetter"
      app
      dense
      flat
      color="header"
    >
      <v-toolbar-title class="pointer nga-logo">{{ $t('website_title') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <top-bar-view/>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <footer-component></footer-component>
    <snack-bar-component></snack-bar-component>
    <dialog-component></dialog-component>

    <!-- Audio elements -->
    <ringing-audio-component/>
    <caller-audio-component/>
<!--    <caller-itr-audio-component/>-->
    <audio id="ringing-busy-audio" src=""></audio>
  </v-app>
</template>

<script>
/* Views */
import TopBarView from '@/app/views/TopBarView'

/* Components */
import RingingAudioComponent from '@/app/components/audio-volume/components/RingingAudioComponent'
import CallerAudioComponent from '@/app/components/audio-volume/components/CallerAudioComponent'
/* import CallerItrAudioComponent from '@/app/components/audio-volume/components/CallerItrAudioComponent' */
import FooterComponent from '@/app/components/footer/components/FooterComponent'
import PageLoaderComponent from '@/app/components/page-loader/PageLoaderComponent'

/* Map Getters */
import { mapGetters } from 'vuex'
import SnackBarComponent from '@/app/shared/notification/components/SnackBarComponent'
import DialogComponent from '@/app/shared/notification/components/DialogComponent'

import localStorageService from '@/util/local-storage-service'
import fontScalingMixin from '@/mixins/font-scaling.mixin'
export default {
  name: 'App',
  mixins: [fontScalingMixin],
  components: {
    TopBarView,
    RingingAudioComponent,
    CallerAudioComponent,
    /* CallerItrAudioComponent, */
    DialogComponent,
    SnackBarComponent,
    FooterComponent,
    PageLoaderComponent
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters('global', ['authUserGetter', 'changeFontSizeGetter']),
    ...mapGetters('settings', ['sipDefaultAddressGetter', 'getUserSetting', 'userSettingsGetter']),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  created () {
    this.checkIfOffline()
    this.checkIfOnline()
    this.getMicrophonePermission()
    this.getAudioDevices()
    this.updateAudioDevices()
  },
  watch: {
    authUserGetter () {
      this.$logger.setUser(this.authUserGetter)
    },
    sipDefaultAddressGetter () {
      this.$logger.setSip(this.sipDefaultAddressGetter)
    },
    userSettingsGetter () {
      this.$logger.setDebugMode(this.getUserSetting('chs_debug_logs')?.value.active)
    },
    changeFontSizeGetter (val) {
      localStorageService.setFontSize(val)
      this.setFontSize()
    }
  },
  mounted () {
    this.$vuetify.theme.dark = localStorageService.getThemeStyle()

    this.$timer.start()

    this.setRingingVolume()
    this.connectionEvents()
    this.incomingEvents()
    this.outgoingEvents()
    this.serialDeviceEvents()

    this.setFontSize()

    setTimeout(() => {
      this.$logger.log('Performance timing:', performance.timing)
    }, 5000)
  }
}
// Test
// Test2
</script>
