import db from '@/modules/db/indexDB/instance'
import agentsDBModule from '@/modules/db/indexDB/modules/agents.db.module'
import speedDialDBModule from '@/modules/db/indexDB/modules/speedDial.db.module'
import callHistoryDBModule from '@/modules/db/indexDB/modules/callHistory.db.module'
import contactBooksDBModule from '@/modules/db/indexDB/modules/contactBooks.db.module'
import contactGroupsDBModule from '@/modules/db/indexDB/modules/contactGroups.db.module'
import abandonedCallsDBModule from '@/modules/db/indexDB/modules/abandonedCalls.db.module'

const indexDBService = {
  agents: agentsDBModule(db),
  speedDial: speedDialDBModule(db),
  callHistory: callHistoryDBModule(db),
  contactBooks: contactBooksDBModule(db),
  contactGroups: contactGroupsDBModule(db),
  abandonedCalls: abandonedCallsDBModule(db),
  db
}

export default indexDBService
