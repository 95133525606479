export default function adrApiModule (instance) {
  return {

    get: async (url) => {
      const resource = `/proxy/url?endpoint_key=${url}`
      return await instance.get(resource, {
        headers: {
          accept: 'application/xml'
        }
      })
    }
  }
}
