<template>
  <div v-if="authUserGetter">

    {{ $t('welcome') }}, {{ authUserGetter.name.toUpperCase() }}

    <connection-button/>
    <abandoned-call-button/>
    <settings-button v-if="$can('can_see_settings')"/>

    <manage-dashboards-button v-if="$canAny(['can_manage_chs_global_dashboards','can_manage_chs_realm_dashboards'])" />

    <admin-panel-button v-if="$can('can_see_admin_panel')" />

    <information-button v-if="false"/>
    <logout-button/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* Buttons */
import ConnectionButton from '@/app/widgets/top-bar-widget/buttons/ConnectionButton'
import AbandonedCallButton from '@/app/widgets/top-bar-widget/buttons/AbandonedCallButton'
import SettingsButton from '@/app/widgets/top-bar-widget/buttons/SettingsButton'
import AdminPanelButton from '@/app/widgets/top-bar-widget/buttons/AdminPanelButton'
import InformationButton from '@/app/widgets/top-bar-widget/buttons/InformationButton'
import LogoutButton from '@/app/widgets/top-bar-widget/buttons/LogoutButton'
import ManageDashboardsButton from '@/app/widgets/top-bar-widget/buttons/ManageDashboardsButton'

export default {
  name: 'TopBarComponent',
  components: {
    ManageDashboardsButton,
    ConnectionButton,
    AbandonedCallButton,
    SettingsButton,
    AdminPanelButton,
    InformationButton,
    LogoutButton
  },
  computed: {
    ...mapGetters('global', ['authUserGetter'])
  }
}
</script>

<style scoped>

</style>
