export default class CADSpillFormat {
  name = 'CADSpillFormat'

  // Optional data, should be filled in child class
  data = {}

  // Mandatory data
  position = null
  dataLength = 0
  responseType = null

  constructor () {
    for (const key in this.data) {
      Object.defineProperty(this, key, {
        get () {
          return this.data[key]
        }
      })
    }
  }

  fillData (data, index, text, length = 0) {
    let i = 0

    // console.log('fillData', arguments)

    // Check for null or undefined
    if (!text) text = ''

    text = text.toString() // In case of number

    text = text.padEnd(length)

    // console.log('length', length)
    // console.log('text.length', text.length)
    // console.log('length || text.length', length || text.length)

    length = length || text.length

    for (i; i < length; i++) {
      // console.log('i', i)
      // console.log('text[i]', text[i])
      data[index + i] = text[i].charCodeAt(0)
    }

    return index + i
  }

  setData (data) {
    for (const key in data) {
      this.data[key] = data[key]
    }

    return this
  }

  setPosition (position) {
    this.position = position

    return this
  }

  setResponseType (responseType) {
    this.responseType = responseType

    return this
  }

  clearData () {
    for (const key in this.data) {
      this.data[key] = null
    }

    return this
  }

  checkData () {
    return this.position && this.dataLength && this.responseType
  }
}
