import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function abandonedCallsDBModule (db) {
  db.version(15).stores({
    abandonedCalls: '++id, call_from'
  })

  return {
    insert: (data) => {
      data.id = parseInt(data.id)

      db.abandonedCalls.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        d.id = parseInt(d.id)

        db.abandonedCalls.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          let tmp = null

          filter = filter || {}

          if (filter.call_from) {
            filter.call_from = filter.call_from.replaceAll('+', '\\+')

            tmp = db.abandonedCalls.filter((value) => {
              return value.call_from.match(new RegExp(filter.call_from, 'ig'))
            }).reverse()
          } else {
            tmp = db.abandonedCalls.reverse()
          }

          if (filter.offset) {
            tmp = tmp.offset(filter.offset)
          }

          if (filter.limit) {
            tmp = tmp.limit(filter.limit)
          }

          return await tmp.toArray()
        })
      )
    },
    delete: (id) => {
      db.abandonedCalls.where({ call_from: id }).delete()
    },
    clear: () => {
      db.abandonedCalls.clear()
    },
    last: () => {
      return db.abandonedCalls.orderBy('id').last()
    },
    count: (filter) => {
      return useObservable(
        liveQuery(async () => {
          if (filter && filter.call_from) {
            filter.call_from = filter.call_from.replaceAll('+', '\\+')

            return db.abandonedCalls.filter((value) => {
              return value.call_from.match(new RegExp(filter.call_from, 'ig'))
            }).count()
          }

          return db.abandonedCalls.count()
        })
      )
    },
    limit: (limit) => {
      return db.abandonedCalls.limit(limit)
    },
    sync: (data) => {
      data.forEach(async d => {
        d.id = parseInt(d.id)

        if (await db.abandonedCalls.get(d.id)) {
          db.abandonedCalls.update(d.id, d)
        } else {
          db.abandonedCalls.add(d)
        }
      })
    }
  }
}
