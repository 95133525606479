import CADSpillFormat4 from './formats/format4'

export default class CADSpill {
  formats = {
    4: new CADSpillFormat4()
  }

  getFormat (format = 4) {
    return this.formats[format]
  }
}
