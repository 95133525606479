<template>
  <code class="transparent">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          cypress="dashboard_settings"
          @click="settingsModal"
          icon
          color="grey_light"
          text
          v-on="on"
        >
          <v-icon>mdi-view-dashboard-variant-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('dashboard_settings') }}</span>
    </v-tooltip>
    <DialogShared @input="closed" v-model="toggleSettings" eager max-width="1200" :title="$t('dashboard_settings')">
      <DashboardSettingsComponent />
    </DialogShared>
  </code>
</template>

<script>

import DialogShared from '@/app/shared/dialog/DialogShared'
import DashboardSettingsComponent from '@/app/widgets/dashboard-widget/DashboardSettingsComponent'

export default {
  name: 'ManageDashboardsButton',
  data: () => ({
    toggleSettings: false
  }),
  components: {
    DialogShared,
    DashboardSettingsComponent
  },
  methods: {

    closed (val) {
      if (!val) { this.clearAudioElements() }
    },

    settingsModal () {
      this.toggleSettings = !this.toggleSettings
    }
  }
}
</script>

<style scoped>

</style>
